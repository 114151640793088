<template>
  <div class="FeaturedInsights">
    <div class="banner">
      <img width="100%" src="../assets/banner5.png" alt="" />
      <div class="title">
        <div class="h1">FEATURED INSIGHTS</div>
      </div>
    </div>
    <div class="nav">
      <div class="minav">
        <div
          class="content"
          v-for="item in data"
          :key="item.articleid"
          @click="handleClick(item.articleid)"
        >
          <img
            :src="`https://www.trusthongkong.com/hktcrm/uploads/newsfeed/${item.articleid}/small_${item.surface_plot}`"
          />
          <p>{{ item.subject }}</p>
          <!-- <p class="time">{{ item.datecreated.substring(0, 10) }}</p> -->
        </div>
      </div>
      <el-pagination
        background
        layout="prev, pager, next"
        :total="total"
        @prev-click="handleprev"
        @next-click="handlenext"
      ></el-pagination>
    </div>
  </div>
</template>
<script>
import http from "../js/http";
export default {
  data() {
    return {
      data: [],
      total: undefined,
      page: 1,
    };
  },
  created() {
    http({
      url: "/hkt/hkt/Information/news_list?lang=en&column=choiceness_article&page=1",
    }).then((res) => {
      this.data = res.data.data.data;
      this.total = res.data.data.total;
      /* console.log(res); */
    });
  },
  methods: {
    handleprev() {
      this.page--;
      http({
        url: `/hkt/hkt/Information/news_list?lang=en&column=choiceness_article&page=${this.page}`,
      }).then((res) => {
        this.data = res.data.data.data;
        this.total = res.data.data.total;
        /* console.log(res); */
      });
    },
    handlenext() {
      this.page++;
      http({
        url: `/hkt/hkt/Information/news_list?lang=en&column=choiceness_article&page=${this.page}`,
      }).then((res) => {
        this.data = res.data.data.data;
        this.total = res.data.data.total;
        /* console.log(res); */
      });
    },
    handleClick(id) {
      this.$router.push(`/Article?id=${id}`);
      /* this.$router.push({
        name: "Article",
        params: {
          myid: id,
        },
      }); */
    },
  },
};
</script>
<style lang="scss" scoped>
::v-deep .el-pagination {
  font-family: contert;
}
.banner {
  width: 100vw;
  /* height: 994px;
  background: url("../assets/banner5.png") no-repeat;
  background-size: cover; */
  position: relative;
}
.title {
  color: #fff;
  display: flex;
  width: 100vw;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  /* z-index: 100; */
  position: absolute;
  top: 45%;
  font-size: 55px;
}
.nav {
  width: 60vw;
  margin: 60px auto 0px;
  text-align: center;
  font-size: 16px;
  img {
    width: 100%;
  }
  .minav {
    display: flex;
    flex-wrap: wrap;
    text-align: left;
    font-family: contert;
    /* justify-content: space-between; */
  }

  .content {
    width: 30%;
    margin-left: 2%;
    margin-right: 1%;
    position: relative;
    overflow: hidden;
    /* padding-bottom: 40px; */
    p {
      margin: 0px 0 20px;
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
    }
    .time {
      color: #666;
      position: relative;
      font-size: 16px;
      bottom: 0px;
      p {
        margin: 0px;
      }
    }
  }
  .content:hover {
    color: #a95228;
    img {
      transform: scale(1.05);
      transition: all 0.6s;
    }
    .time {
      color: #a95228;
    }
  }
}
@media (min-width: 1025px) and (max-width: 1500px) {
  .nav {
    width: 80vw;
  }
}
@media (min-width: 768px) and (max-width: 1024px) {
  .title .h1 {
    font-size: 36px;
  }
  .icon {
    font-size: 16px;
  }
  .nav {
    width: 80vw;
    margin-top: 75px;
    margin-bottom: 100px;
    .content {
      padding-bottom: 30px;
      p {
        font-size: 20px;
        margin: 0px;
      }
      .time {
        font-size: 16px;
        margin-top: 10px;
      }
    }
  }
}
@media (max-width: 767px) {
  // 手机背景图
  // .banner img {
  //   height: 520px;
  //   object-fit: cover;
  // }
  .title .h1 {
    font-size: 30px;
  }

  .banner {
    margin-top: 64px;
    .title {
      top: 35%;
    }
  }
  .nav {
    width: 90vw;
    margin: 30px auto 20px;
    .minav {
      justify-content: space-between;
    }
    .content {
      margin: 0px;
      width: 48%;
      padding-bottom: 10px;
      p {
        font-size: 16px;
        margin: 0px;
      }
      .time {
        font-size: 14px;
      }
    }
  }
}
</style>
